import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Gao from './Gao.js';



function App() {
    return (
        <Router>
          <Route exact path="/" component={Home} />
          <Route path="/gao" component={Gao} />
        </Router>
    );
}

function Home() {
    return (
	<div className="App">
	  <header className="App-header">
	    <h1>
	      看什么看
	    </h1>
	  </header>
	</div>
    );
}

export default App;
