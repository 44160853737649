import React from 'react';


function Gao() {
    return (
	    <h1>
	    嘻嘻嘻
	    </h1>
    )
}

export default Gao;
